/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AggregatedTimeSeriesId {
  CO2 = "CO2",
  HEALTHSCORE = "HEALTHSCORE",
  HUMIDITY = "HUMIDITY",
  PRESSURE = "PRESSURE",
  TEMPERATURE = "TEMPERATURE",
  VOC = "VOC",
}

export enum AudienceType {
  api = "api",
  session = "session",
}

export enum BuildingPerformanceIndicatorId {
  AVERAGE_HEALTHSCORE = "AVERAGE_HEALTHSCORE",
  PRODUCTIVE_TIME = "PRODUCTIVE_TIME",
  UNPRODUCTIVE_TIME = "UNPRODUCTIVE_TIME",
}

/**
 * Device capability
 */
export enum DeviceCapability {
  IAQ_SENSOR = "IAQ_SENSOR",
  LIGHT = "LIGHT",
  PIR_SENSOR = "PIR_SENSOR",
  PM_SENSOR = "PM_SENSOR",
}

/**
 * LoRaWAN Device Class
 */
export enum DeviceClass {
  A = "A",
  C = "C",
}

/**
 * Available Feature Toggles
 */
export enum FeatureToggle {
  API_TOKEN_MANAGEMENT = "API_TOKEN_MANAGEMENT",
  COVID_CALCULATOR = "COVID_CALCULATOR",
  LIGHT_CONFIGURATION = "LIGHT_CONFIGURATION",
  PM_ANALYTICS = "PM_ANALYTICS",
  PRESENCE = "PRESENCE",
  REPORTING = "REPORTING",
}

export enum GatewayType {
  MiromicoPicoCell = "MiromicoPicoCell",
  RAK = "RAK",
}

export enum LightState {
  GREEN_500 = "GREEN_500",
  OFF = "OFF",
  ORANGE_500 = "ORANGE_500",
  RED_500 = "RED_500",
  RED_500_PULSATING = "RED_500_PULSATING",
  WHITE_300 = "WHITE_300",
  YELLOW_500 = "YELLOW_500",
}

export enum LteProvider {
  bics_eu = "bics_eu",
  swisscom_ch = "swisscom_ch",
}

/**
 * AIRICA automatically asigns measurement levels to measurement values.
 *     More details about the measurement levels can be found in the support desk at https: // support.airica.com
 */
export enum MeasurementLevel {
  LEVEL_0 = "LEVEL_0",
  LEVEL_1 = "LEVEL_1",
  LEVEL_2 = "LEVEL_2",
  LEVEL_3 = "LEVEL_3",
  LEVEL_4 = "LEVEL_4",
  LEVEL_5 = "LEVEL_5",
}

export enum NetworkProvider {
  HELIUM = "HELIUM",
  LORIOT = "LORIOT",
}

export enum OrderSortField {
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}

export enum OrderStatus {
  completed = "completed",
  new = "new",
  processing = "processing",
}

export enum PerformanceIndicatorId {
  AVERAGE_HEALTHSCORE = "AVERAGE_HEALTHSCORE",
  AVERAGE_HUMIDITY = "AVERAGE_HUMIDITY",
  AVERAGE_PRESSURE = "AVERAGE_PRESSURE",
  AVERAGE_TEMPERATURE = "AVERAGE_TEMPERATURE",
  MAX_CO2 = "MAX_CO2",
  PRODUCTIVE_TIME = "PRODUCTIVE_TIME",
  PRODUCTIVITY_SAVING_POTENTIAL = "PRODUCTIVITY_SAVING_POTENTIAL",
  UNPRODUCTIVE_TIME = "UNPRODUCTIVE_TIME",
}

export enum PerformanceIndicatorTrend {
  BETTER = "BETTER",
  UNCHANGED = "UNCHANGED",
  WORSE = "WORSE",
}

export enum QueryMode {
  default = "default",
  insensitive = "insensitive",
}

export enum RoomPerformanceIndicatorId {
  AVERAGE_HEALTHSCORE = "AVERAGE_HEALTHSCORE",
  AVERAGE_HUMIDITY = "AVERAGE_HUMIDITY",
  AVERAGE_PRESSURE = "AVERAGE_PRESSURE",
  AVERAGE_TEMPERATURE = "AVERAGE_TEMPERATURE",
  MAX_CO2 = "MAX_CO2",
  PRODUCTIVE_TIME = "PRODUCTIVE_TIME",
  PRODUCTIVITY_SAVING_POTENTIAL = "PRODUCTIVITY_SAVING_POTENTIAL",
  UNPRODUCTIVE_TIME = "UNPRODUCTIVE_TIME",
}

/**
 * A proper room type is important for some functionality such as Particulate Matter or Presence detection. E.g. the type `outdoor` can be used 
 */
export enum RoomType {
  closed_room = "closed_room",
  open_space = "open_space",
  outdoor = "outdoor",
}

export enum SortOrder {
  asc = "asc",
  desc = "desc",
}

/**
 * The following measurements can be fetched as a timeseries:
 * <table>
 *   <thead>
 *     <tr>
 *       <th>TimeSeriesId</th>
 *       <th>Description</th>
 *     </tr>
 *   </thead>
 *   <tbody>
 *     <tr>
 *       <td>BATTERY_VOLTAGE</td>
 *       <td>The voltage of the sensor battery in V</td>
 *     </tr>
 *     <tr>
 *       <td>CO2</td>
 *       <td>CO2 concentration in ppm</td>
 *     </tr>
 *     <tr>
 *       <td>HEALTHSCORE</td>
 *       <td>The healthscore between 1-10. More details can be found at https: // support.airica.com/en/what-is-the-airica-health-score</td>
 *     </tr>
 *     <tr>
 *       <td>HUMIDITY</td>
 *       <td>Air humidity in %</td>
 *     </tr>
 *     <tr>
 *     <td>PRESSURE</td>
 *     <td>Barometric pressure in Pa</td>
 *   </tr>
 *     <tr>
 *       <td>PIR_TRIGGERED</td>
 *       <td>Motion detection of a passive infrared (PIR) sensor.
 *       Value of a data point is 0 or 1 and does not have a unit.
 *       The time series is irregular, i.e.: the measurements do not happen at
 *       a regular time interval.
 *       Interpolating a value of this time series for any given point in time
 *       is achieved by looking up the data point that was recorder immediately
 *       before the point in time of interest.</td>
 *     </tr>
 *     <tr>
 *       <td>PM_1_0_MASS</td>
 *       <td>PM1 (Particulate Matter) in µg/m3</td>
 *     </tr>
 *     <tr>
 *       <td>PM_2_5_MASS</td>
 *       <td>PM2.5 (Particulate Matter) in µg/m3</td>
 *     </tr>
 *     <tr>
 *       <td>PM_10_MASS</td>
 *       <td>PM10 (Particulate Matter) in µg/m3</td>
 *     </tr>
 *     <tr>
 *       <td>PRESENCE</td>
 *       <td>Indicates whether a room was occupied or not</td>
 *     </tr>
 *     <tr>
 *       <td>TEMPERATURE</td>
 *       <td>Temperature in °C</td>
 *     </tr>
 *     <tr>
 *       <td>VOC</td>
 *       <td>Volatile organic compound (VOC) in ppb</td>
 *     </tr>
 *   </tbody>
 * </table>
 */
export enum TimeSeriesId {
  BATTERY_VOLTAGE = "BATTERY_VOLTAGE",
  CO2 = "CO2",
  HEALTHSCORE = "HEALTHSCORE",
  HUMIDITY = "HUMIDITY",
  PIR_TRIGGERED = "PIR_TRIGGERED",
  PM_10_MASS = "PM_10_MASS",
  PM_1_0_MASS = "PM_1_0_MASS",
  PM_2_5_MASS = "PM_2_5_MASS",
  PRESENCE = "PRESENCE",
  PRESSURE = "PRESSURE",
  TEMPERATURE = "TEMPERATURE",
  VOC = "VOC",
}

export enum Unit {
  DEGREE_CELSIUS = "DEGREE_CELSIUS",
  MICROGRAM_PER_CUBIC_METER = "MICROGRAM_PER_CUBIC_METER",
  MONEY = "MONEY",
  PARTS_PER_BILLION = "PARTS_PER_BILLION",
  PARTS_PER_MILLION = "PARTS_PER_MILLION",
  PASCAL = "PASCAL",
  PERCENTAGE = "PERCENTAGE",
  SECONDS = "SECONDS",
  UNITLESS = "UNITLESS",
  VOLTAGE = "VOLTAGE",
}

export enum UserRole {
  ADMIN = "ADMIN",
  PARTNER = "PARTNER",
  USER = "USER",
}

export interface AccessTokenCreateInput {
  description: string;
}

/**
 * Please refer to the building object for field explanations
 */
export interface BuildingCreateInput {
  addressCity?: string | null;
  addressCountry?: string | null;
  addressNumber?: string | null;
  addressState?: string | null;
  addressStreet?: string | null;
  addressZipCode?: string | null;
  clientId: string;
  name: string;
}

/**
 * Please refer to the building object for field explanations
 */
export interface BuildingUpdateInput {
  addressCity?: string | null;
  addressCountry?: string | null;
  addressNumber?: string | null;
  addressState?: string | null;
  addressStreet?: string | null;
  addressZipCode?: string | null;
  name?: string | null;
}

export interface ClientCreateAdminInput {
  currencyCode?: string | null;
  hourlyEmployeeLaborCost?: number | null;
  logo?: string | null;
  logoFileName?: string | null;
  name: string;
  nameInternal: string;
  networkProvider: NetworkProvider;
  timezone: string;
}

export interface ClientFeatureUpsertInput {
  active: boolean;
  clientId: string;
  featureName: FeatureToggle;
}

export interface ClientUpdateAdminInput {
  currencyCode?: string | null;
  hourlyEmployeeLaborCost?: number | null;
  logo?: string | null;
  logoFileName?: string | null;
  name?: string | null;
  timezone?: string | null;
}

/**
 * Please refer to the client object for field explanations
 */
export interface ClientUpdateInput {
  currencyCode?: string | null;
  hourlyEmployeeLaborCost?: number | null;
  lightControlConfiguration?: LightControlConfigurationInput | null;
  logo?: string | null;
  name?: string | null;
}

export interface ClientWhereUniqueInput {
  id?: string | null;
  nameInternal?: string | null;
}

export interface DeviceAssignRoomInput {
  roomId?: string | null;
}

export interface DeviceCreateAdminInput {
  appeui: string;
  appkey: string;
  clientId?: string | null;
  deviceTypeId: string;
  eui: string;
  roomId?: string | null;
}

export interface DeviceTestInput {
  eui: string;
  orderId: string;
}

export interface DeviceUpdateAdminInput {
  appeui?: string | null;
  appkey?: string | null;
  clientId?: string | null;
  deviceTypeId?: string | null;
  eui?: string | null;
  roomId?: string | null;
}

export interface DeviceWhereInput {
  AND?: DeviceWhereInput[] | null;
  NOT?: DeviceWhereInput[] | null;
  OR?: DeviceWhereInput[] | null;
  clientId?: StringNullableFilter | null;
  deviceTypeId?: StringNullableFilter | null;
  eui?: StringNullableFilter | null;
  humanReadableId?: IntNullableFilter | null;
  id?: StringFilter | null;
  roomId?: StringNullableFilter | null;
  searchTerm?: string | null;
}

export interface DevicesAssignAdminInput {
  clientId: string;
  numberOfAirQualitySensors: number;
  numberOfLights: number;
}

export interface GatewayCreateAdminInput {
  buildingId?: string | null;
  clientId?: string | null;
  eui?: string | null;
  gatewayType: GatewayType;
  lteImei?: string | null;
  lteProvider?: LteProvider | null;
  mac: string;
}

export interface GatewayTestInput {
  mac: string;
  orderId: string;
}

export interface GatewayUpdateAdminInput {
  buildingId?: string | null;
  clientId?: string | null;
  lteImei?: string | null;
  lteProvider?: LteProvider | null;
}

export interface GatewayWhereInput {
  clientId?: string | null;
  searchTerm?: string | null;
}

export interface IntNullableFilter {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: NestedIntNullableFilter | null;
  notIn?: number[] | null;
}

export interface LightControlConfigurationInput {
  name?: string | null;
  thresholds?: (ThresholdInput | null)[] | null;
}

export interface NestedIntNullableFilter {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: NestedIntNullableFilter | null;
  notIn?: number[] | null;
}

export interface NestedStringFilter {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  not?: NestedStringFilter | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface NestedStringNullableFilter {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  not?: NestedStringNullableFilter | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface OrderItemCreateInput {
  deviceTypeId?: string | null;
  gatewayType?: GatewayType | null;
  quantity: number;
}

export interface OrderOrderByInput {
  direction?: SortOrder | null;
  fieldName?: OrderSortField | null;
}

export interface OrderProcessingInput {
  deviceEuis: (string | null)[];
  gatewayMacs: (string | null)[];
}

/**
 * Updates the list of scanned devices and gateways in order processing.
 */
export interface OrderProcessingUpdateInput {
  deviceEuis: string[];
  gatewayMacs: string[];
}

export interface OrderUpdateInput {
  clientId: string;
  items?: (OrderItemCreateInput | null)[] | null;
  status?: OrderStatus | null;
}

export interface OrderWhereInput {
  clientId?: string | null;
  status?: OrderStatus[] | null;
}

/**
 * Please refer to the room object for field explanations
 */
export interface RoomCreateInput {
  buildingId: string;
  doors?: number | null;
  hasAc?: boolean | null;
  hasVentilation?: boolean | null;
  height?: number | null;
  length?: number | null;
  mainIaqSensorId?: string | null;
  maxOccupancy?: number | null;
  name: string;
  roomType: RoomType;
  volume?: number | null;
  width?: number | null;
  windows?: number | null;
}

export interface RoomUpdateAdminInput {
  baseVentilationRate?: number | null;
  breakVentilationRate?: number | null;
  buildingId?: string | null;
  doors?: number | null;
  hasAc?: boolean | null;
  hasVentilation?: boolean | null;
  height?: number | null;
  length?: number | null;
  mainIaqSensorId?: string | null;
  maxOccupancy?: number | null;
  name?: string | null;
  roomType?: RoomType | null;
  volume?: number | null;
  width?: number | null;
  windows?: number | null;
}

/**
 * Please refer to the room object for field explanations
 */
export interface RoomUpdateInput {
  buildingId?: string | null;
  doors?: number | null;
  hasAc?: boolean | null;
  hasVentilation?: boolean | null;
  height?: number | null;
  length?: number | null;
  mainIaqSensorId?: string | null;
  maxOccupancy?: number | null;
  name?: string | null;
  roomType?: RoomType | null;
  volume?: number | null;
  width?: number | null;
  windows?: number | null;
}

export interface SharedRoomCodeWhereUniqueInput {
  id?: string | null;
  roomId?: string | null;
  shareCode?: string | null;
}

export interface StringFilter {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: QueryMode | null;
  not?: NestedStringFilter | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface StringNullableFilter {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: QueryMode | null;
  not?: NestedStringNullableFilter | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ThresholdInput {
  state?: LightState | null;
  value?: number | null;
}

export interface UserCreateAdminInput {
  clientId?: string | null;
  email: string;
  password: string;
  passwordChangeRequired: boolean;
  role: UserRole;
}

export interface UserUpdateAdminInput {
  clientId?: string | null;
  email?: string | null;
  password?: string | null;
  passwordChangeRequired?: boolean | null;
  role?: UserRole | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================

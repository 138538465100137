import {
  captureException,
  configureScope,
  init,
  withScope,
} from "@sentry/react";
import { Me } from "./modules/shared/auth/hooks/types/Me";
import isEmpty from "lodash/isEmpty";
import { onError } from "@apollo/client/link/error";

export const initSentry = () => {
  init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    release: process.env.NEXT_PUBLIC_BUILD_ID,
    environment: "N/A",
  });
};

export const sentryErrorLink = onError(
  ({ graphQLErrors, networkError, operation, response }) => {
    withScope((scope) => {
      const transactionId =
        operation?.getContext()?.headers["X-Transaction-Id"];
      if (transactionId) {
        scope.setTag("transaction_id", transactionId);
      }

      scope.setTag("operationName", operation.operationName);
      scope.setExtra("querySource", operation.query?.loc?.source);
      scope.setExtra(
        "variables",
        filterSensitiveVariableValues(operation.variables)
      );
      if (operation.extensions && !isEmpty(operation.extensions)) {
        scope.setExtra("operationExtensions", operation.extensions);
      }
      if (graphQLErrors) {
        graphQLErrors
          .filter(
            (e) =>
              !e.extensions?.code ||
              e.extensions.code === "INTERNAL_SERVER_ERROR"
          )
          .forEach((e) => captureException(e.message));
      }

      if (networkError) {
        captureException(networkError);
      }
    });
  }
);

export const newSentryTransactionIdHeader = () => {
  // Transaction id for Sentry
  // See https://blog.sentry.io/2019/04/04/trace-errors-through-stack-using-unique-identifiers-in-sentry#1-generate-a-unique-identifier-and-set-as-a-sentry-tag-on-issuing-service
  const transactionId = Math.random().toString(36).substr(2, 9);
  configureScope((scope) => scope.setTag("transaction_id", transactionId));
  return {
    "X-Transaction-Id": transactionId,
  };
};

export const addEnvNameToSentryScope = (envName: string) => {
  configureScope((scope) =>
    scope.addEventProcessor((event) => {
      return {
        ...event,
        environment: envName,
      };
    })
  );
};

export const addUserToSentryScope = (data: Me) => {
  if (data?.me) {
    configureScope((scope) =>
      scope.setUser({
        id: data.me.id,
        role: data.me.role,
        client_id: data.me.client?.id,
      })
    );
  }
};

// copied from "@amaabca/sensitive-param-filter"
const sensitiveVariableKeyTokens = [
  "auth",
  "bearer",
  "credit",
  "CVD",
  "CVV",
  "encrypt",
  "PAN",
  "pass",
  "secret",
  "token",
  "email",
];
const sensitiveVariableKeysRegex = new RegExp(
  sensitiveVariableKeyTokens.join("|"),
  "i"
);

// We don't use the npm package "@amaabca/sensitive-param-filter" in the client code
// because it results in a huge increase in bundle size.
// The reason for the increase: sensitive-param-filter uses internally node 'crypto' package,
// so it probably shouldn't be used in the frontend code at all.
// See also comment on merge request:
// https://gitlab.com/eden-senses/airica-app/-/merge_requests/357#note_824483658
//
// Btw: Just discovered that sentry applies also some data scrubbing
// (data get replaced by '[Filtered]'). See:
// https://help.sentry.io/account/security/why-am-i-seeing-filtered-in-my-event-data/
// https://forum.sentry.io/t/what-does-filtered-mean-in-the-additional-data/448/4
const filterSensitiveVariableValues = (variables: Record<string, any>) => {
  return Object.entries(variables).reduce((copyWithRedactedValues, cur) => {
    if (sensitiveVariableKeysRegex.test(cur[0])) {
      copyWithRedactedValues[cur[0]] = "[Filtered-on-client-by-airica]";
    } else {
      copyWithRedactedValues[cur[0]] = cur[1];
    }
    return copyWithRedactedValues;
  }, {} as Record<string, any>);
};

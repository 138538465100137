import { Link, LinkProps } from "@mui/material";
import React from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const FaqLink: React.FC<LinkProps> = (props) => {
  return (
    <Link
      onClick={(e) => {
        // prevent propagation as default behaviour, e.g. to prevent triggering
        // sorting by the table column that contains this link
        e.stopPropagation();
      }}
      {...props}
    >
      <HelpOutlineIcon />
    </Link>
  );
};

export default FaqLink;

import React from "react";
import { TimeSeriesId } from "../../../../../types/global-types";
import HealthScoreFaqLink from "../HealthScoreFaqLink";
import { Trans } from "next-i18next";
import { CovidIndicator } from "../dashboard/covidCalculator";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import FaqLink from "../../../../shared/common/components/FaqLink";
import { TimeSeriesFragment_data } from "../../../room/hooks/types/TimeSeriesFragment";
import { getLastObservationCarriedForwardFn } from "../../../../../utils/lastObservationCarriedForward";

dayjs.extend(duration);
dayjs.extend(relativeTime);

export type TimeSeriesConfigKey = TimeSeriesId | CovidIndicator;

export type TimeSeriesConfig = {
  yMin?: number;
  yMax?: number;
  yTickValues?: number;
  precision?: number;
  dataMappingFn?: (
    dataPoints: TimeSeriesFragment_data[]
  ) => TimeSeriesFragment_data[];
};

export type TranslatedTimeSeriesConfig = TimeSeriesConfig & {
  title: React.ReactNode;
  yLabel: React.ReactNode;
  valueFormatter?: (
    value: number,
    precision: number,
    unit: string
  ) => React.ReactNode;
  unit?: string;
  infoLink?: React.ReactNode;
};

const pmMassConcentrationConfig = (
  t,
  id: TimeSeriesId
): TranslatedTimeSeriesConfig => {
  const title = (
    <Trans
      i18nKey={`timeseries.${id}.title`}
      t={t}
      components={[<sub key="0" />]}
    />
  );
  return {
    title: title,
    yLabel: title,
    unit: " \u03BCg/m\u00B3",
  };
};

export const timeSeriesConfigs: Record<TimeSeriesConfigKey, TimeSeriesConfig> =
  {
    PRESENCE: {
      yMin: 0,
      yMax: 1,
      yTickValues: 1,
    },
    HEALTHSCORE: {
      precision: 1,
      yMin: 1,
      yMax: 10,
    },
    CO2: {
      precision: 0,
    },
    TEMPERATURE: {
      precision: 1,
    },
    HUMIDITY: {
      precision: 0,
    },
    PRESSURE: {
      precision: 0,
    },
    VOC: {
      precision: 0,
    },
    BATTERY_VOLTAGE: {
      precision: 2,
    },
    PM_1_0_MASS: {
      precision: 2,
    },
    PM_2_5_MASS: {
      precision: 2,
    },
    PM_10_MASS: {
      precision: 2,
    },
    COVID_CONCENTRATION: {
      precision: 1,
    },
    COVID_HALF_LIFE: {
      precision: 0,
    },
    PIR_TRIGGERED: {
      precision: 0,
      yMin: 0,
      yMax: 1,
      yTickValues: 1,
      dataMappingFn: getLastObservationCarriedForwardFn({
        lastMeasurementMaxDelayInSeconds: 30 * 60,
      }),
    },
  };

export const getTranslatedTimeSeriesConfigs = (
  t
): Record<TimeSeriesConfigKey, TranslatedTimeSeriesConfig> => ({
  PRESENCE: {
    ...timeSeriesConfigs.PRESENCE,
    valueFormatter: (value) => (value ? t("yes") : t("no")),
    title: t("timeseries.PRESENCE.title"),
    yLabel: t("timeseries.PRESENCE.label"),
  },
  HEALTHSCORE: {
    ...timeSeriesConfigs.HEALTHSCORE,
    yLabel: t("healthScore.score"),
    unit: "",
    title: t("healthScore.score"),
    infoLink: <HealthScoreFaqLink key="link" />,
  },
  CO2: {
    ...timeSeriesConfigs.CO2,
    title: t("co2"),
    yLabel: t("co2"),
    unit: t("unit.PARTS_PER_MILLION"),
  },
  TEMPERATURE: {
    ...timeSeriesConfigs.TEMPERATURE,
    title: t("iaqMeasurementSet.temperature"),
    yLabel: t("iaqMeasurementSet.temperature"),
    unit: "°C",
  },
  HUMIDITY: {
    ...timeSeriesConfigs.HUMIDITY,
    title: t("iaqMeasurementSet.humidity"),
    yLabel: t("iaqMeasurementSet.humidity"),
    unit: t("unit.PERCENTAGE"),
  },
  PRESSURE: {
    ...timeSeriesConfigs.PRESSURE,
    title: t("iaqMeasurementSet.pressure"),
    yLabel: t("iaqMeasurementSet.pressure"),
    unit: t("unit.PASCAL"),
  },
  VOC: {
    ...timeSeriesConfigs.VOC,
    title: t("iaqMeasurementSet.totalVoc"),
    yLabel: t("iaqMeasurementSet.totalVoc"),
    unit: t("unit.PARTS_PER_BILLION"),
  },
  BATTERY_VOLTAGE: {
    ...timeSeriesConfigs.BATTERY_VOLTAGE,
    title: t("iaqMeasurementSet.batteryVoltage"),
    yLabel: t("iaqMeasurementSet.batteryVoltage"),
    unit: t("unit.VOLTAGE"),
  },
  PM_1_0_MASS: {
    ...timeSeriesConfigs.PM_1_0_MASS,
    ...pmMassConcentrationConfig(t, TimeSeriesId.PM_1_0_MASS),
  },
  PM_2_5_MASS: {
    ...timeSeriesConfigs.PM_2_5_MASS,
    ...pmMassConcentrationConfig(t, TimeSeriesId.PM_2_5_MASS),
  },
  PM_10_MASS: {
    ...timeSeriesConfigs.PM_10_MASS,
    ...pmMassConcentrationConfig(t, TimeSeriesId.PM_10_MASS),
  },
  COVID_CONCENTRATION: {
    ...timeSeriesConfigs.COVID_CONCENTRATION,
    title: t(`covidCalculator.${CovidIndicator.COVID_CONCENTRATION}`),
    unit: " q/m\u00B3",
    yLabel: undefined,
    infoLink: (
      <FaqLink
        key="2"
        href={t("covidCalculator.infoLink.href")}
        target="_blank"
        title={t("covidCalculator.infoLink.title")}
      />
    ),
  },
  COVID_HALF_LIFE: {
    ...timeSeriesConfigs.COVID_HALF_LIFE,
    title: t(`covidCalculator.${CovidIndicator.COVID_HALF_LIFE}`),
    unit: " min",
    yLabel: undefined,
    infoLink: (
      <FaqLink
        key="2"
        href={t("covidCalculator.infoLink.href")}
        target="_blank"
        title={t("covidCalculator.infoLink.title")}
      />
    ),
  },
  PIR_TRIGGERED: {
    ...timeSeriesConfigs.PIR_TRIGGERED,
    title: t(`timeseries.${TimeSeriesId.PIR_TRIGGERED}.title`),
    yLabel: t(`timeseries.${TimeSeriesId.PIR_TRIGGERED}.label`),
    valueFormatter: (value) => (value ? t("yes") : t("no")),
  },
});

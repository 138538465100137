import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { CONFIG_FRAGMENT } from "./fragments";
import { Config } from "./types/Config";

export const CONFIG_QUERY = gql`
  query Config {
    config {
      ...ConfigFragment
    }
  }
  ${CONFIG_FRAGMENT}
`;

export const useConfig = () =>
  useQuery<Config>(CONFIG_QUERY, { fetchPolicy: "cache-and-network" });

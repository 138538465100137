import { LinkProps } from "@mui/material";
import React from "react";
import { useTranslation } from "next-i18next";
import FaqLink from "../../../shared/common/components/FaqLink";

const HealthScoreFaqLink: React.FC<LinkProps> = (props) => {
  const { t } = useTranslation();
  return (
    <FaqLink
      href={t("iaqMeasurementSet.healthScoreFaqLink")}
      target="_blank"
      title={t("iaqMeasurementSet.healthScoreFaqLinkText")}
      {...props}
    />
  );
};

export default HealthScoreFaqLink;

import { LocalDateTime } from "../../types";
import { Unit } from "../../../../../lib/util/unit";
import { DateRange } from "../../../../../lib/util/dateRange";
import { AggregatedTimeSeriesId } from "./aggregation";
import dayjs from "dayjs";
import { RoomWithClient } from "../../../room/service/RoomService";

export enum TimeSeriesId {
  CO2 = "CO2",
  HUMIDITY = "HUMIDITY",
  PRESSURE = "PRESSURE",
  TEMPERATURE = "TEMPERATURE",
  VOC = "VOC",
  HEALTHSCORE = "HEALTHSCORE",
  PRESENCE = "PRESENCE",
  BATTERY_VOLTAGE = "BATTERY_VOLTAGE",
  PM_1_0_MASS = "PM_1_0_MASS",
  PM_2_5_MASS = "PM_2_5_MASS",
  PM_10_MASS = "PM_10_MASS",
  PIR_TRIGGERED = "PIR_TRIGGERED",
}

export const iaqSeriesIds = new Set<TimeSeriesId>([
  TimeSeriesId.CO2,
  TimeSeriesId.HUMIDITY,
  TimeSeriesId.PRESSURE,
  TimeSeriesId.TEMPERATURE,
  TimeSeriesId.VOC,
  TimeSeriesId.HEALTHSCORE,
  TimeSeriesId.PRESENCE,
  TimeSeriesId.BATTERY_VOLTAGE,
]);

export const pmTimeSeriesIds = new Set<TimeSeriesId>([
  TimeSeriesId.PM_10_MASS,
  TimeSeriesId.PM_2_5_MASS,
  TimeSeriesId.PM_1_0_MASS,
]);

export const pirTimeSeriesIds = new Set<TimeSeriesId>([
  TimeSeriesId.PIR_TRIGGERED,
]);

export enum MeasurementLevel {
  LEVEL_0 = "LEVEL_0",
  LEVEL_1 = "LEVEL_1",
  LEVEL_2 = "LEVEL_2",
  LEVEL_3 = "LEVEL_3",
  LEVEL_4 = "LEVEL_4",
  LEVEL_5 = "LEVEL_5",
}

export enum BuildingPerformanceIndicatorId {
  AVERAGE_HEALTHSCORE = "AVERAGE_HEALTHSCORE",
  PRODUCTIVE_TIME = "PRODUCTIVE_TIME",
  UNPRODUCTIVE_TIME = "UNPRODUCTIVE_TIME",
}

export enum RoomPerformanceIndicatorId {
  AVERAGE_HEALTHSCORE = "AVERAGE_HEALTHSCORE",
  PRODUCTIVE_TIME = "PRODUCTIVE_TIME",
  UNPRODUCTIVE_TIME = "UNPRODUCTIVE_TIME",
  PRODUCTIVITY_SAVING_POTENTIAL = "PRODUCTIVITY_SAVING_POTENTIAL",
  AVERAGE_TEMPERATURE = "AVERAGE_TEMPERATURE",
  AVERAGE_HUMIDITY = "AVERAGE_HUMIDITY",
  AVERAGE_PRESSURE = "AVERAGE_PRESSURE",
  MAX_CO2 = "MAX_CO2",
}

export type PerformanceIndicatorId =
  | BuildingPerformanceIndicatorId
  | RoomPerformanceIndicatorId;

export enum PerformanceIndicatorTrend {
  BETTER = 1,
  UNCHANGED = 0,
  WORSE = -1,
}

export type TimeSeriesDataPoint = {
  time: Date;
  value: number;
  level?: MeasurementLevel;
};

export type TimeSeries = {
  id: TimeSeriesId;
  data: TimeSeriesDataPoint[];
};

export type HistogramBin = {
  level: MeasurementLevel;
  count: number;
  durationInSecs: number;
};

export type Histogram = {
  id: TimeSeriesId;
  confidence: number;
  unit: Unit;
  bins: HistogramBin[];
};

export type TrendData = {
  comparisonStartDateIncl: string;
  comparisonEndDateExcl: string;
  comparisonValue?: number;
  trend?: PerformanceIndicatorTrend;
};

export type PerformanceIndicator = {
  id: PerformanceIndicatorId;
  unit: Unit;
  confidence: number;
  value?: number;
  trendData?: TrendData;
  level?: MeasurementLevel;
};

export type HourlyAveragePerWeekday = {
  id: AggregatedTimeSeriesId;
  hourOfDay: number;
  isoWeekday: number;
  measurementCount: number;
  dayCount: number;
  confidence: number;
  unit: Unit;
  value?: number;
  level?: MeasurementLevel;
};

export interface IHistogramsService {
  getHistograms(
    deviceId: string,
    dateRange: DateRange,
    onlyWorkingHours: boolean,
    timezone: string,
    aggregatedTimeSeriesIds: AggregatedTimeSeriesId[]
  ): Promise<Histogram[]>;

  getAccumulatedHistograms(
    deviceIds: string[],
    dateRange: DateRange,
    onlyWorkingHours: boolean,
    timezone: string,
    aggregatedTimeSeriesIds: AggregatedTimeSeriesId[]
  ): Promise<Histogram[]>;
}

export interface ITimeSeriesService {
  getTimeSeries(
    deviceId: string,
    startDate: Date,
    endDate: Date,
    timeseriesIds: TimeSeriesId[]
  ): Promise<TimeSeries[]>;

  getLatestTimeSeries(
    deviceId: string,
    timeSeriesIds: TimeSeriesId[]
  ): Promise<Partial<Record<TimeSeriesId, TimeSeriesDataPoint>>>;

  getLatestCo2Measurement(deviceId: string): Promise<number>;
}

export interface IRoomPerformanceIndicatorsService {
  getPerformanceIndicators(
    roomWithClient: RoomWithClient,
    dateRange: DateRange,
    onlyWorkingHours: boolean,
    performanceIndicatorIds: RoomPerformanceIndicatorId[],
    calculateTrend: boolean
  ): Promise<PerformanceIndicator[]>;
}

export interface IBuildingPerformanceIndicatorsService {
  getPerformanceIndicators(
    roomsWithClient: RoomWithClient[],
    dateRange: DateRange,
    onlyWorkingHours: boolean,
    performanceIndicatorIds: BuildingPerformanceIndicatorId[],
    calculateTrend: boolean
  ): Promise<PerformanceIndicator[]>;
}

export interface IAnalyticsService extends ITimeSeriesService {
  getBuildingHistograms(
    buildingId: string,
    localStartDate: LocalDateTime,
    localEndDate: LocalDateTime,
    onlyWorkingHours: boolean,
    aggregatedTimeSeriesIds: AggregatedTimeSeriesId[]
  ): Promise<Histogram[]>;

  getRoomHistograms(
    roomId: string,
    localStartDate: LocalDateTime,
    localEndDate: LocalDateTime,
    onlyWorkingHours: boolean,
    aggregatedTimeSeriesIds: AggregatedTimeSeriesId[]
  ): Promise<Histogram[]>;

  getBuildingPerformanceIndicators(
    buildingId: string,
    localStartDate: LocalDateTime,
    localEndDate: LocalDateTime,
    onlyWorkingHours: boolean,
    performanceIndicatorIds: BuildingPerformanceIndicatorId[],
    calculateTrend: boolean
  ): Promise<PerformanceIndicator[]>;

  getRoomPerformanceIndicators(
    roomId: string,
    localStartDate: LocalDateTime,
    localEndDate: LocalDateTime,
    onlyWorkingHours: boolean,
    performanceIndicatorIds: RoomPerformanceIndicatorId[],
    calculateTrend: boolean
  ): Promise<PerformanceIndicator[]>;

  getUsage(
    roomId: string,
    localStartDate: LocalDateTime,
    localEndDate: LocalDateTime
  ): Promise<{ time: string; value: number }[]>;

  getHourlyAveragePerWeekday(
    roomId: string,
    localStartDate: LocalDateTime,
    localEndDate: LocalDateTime,
    onlyWorkingHours: boolean,
    aggregatedTimeSeriesIds: AggregatedTimeSeriesId[]
  ): Promise<HourlyAveragePerWeekday[]>;
}

export const expectedMeasurementsPerHour = 30;

export const workDayDefinition = {
  startHourInclusive: 7,
  endHourExclusive: 19,
};

export const workDayDurationInHours =
  workDayDefinition.endHourExclusive - workDayDefinition.startHourInclusive;

export const durationInSecs = ({
  dateRange,
  onlyWorkingHours,
}: {
  dateRange: DateRange;
  onlyWorkingHours: boolean;
}): number => {
  const { startDate, endDate } = dateRange.getBoundariesWithExclusiveEnd();
  let duration: number;
  if (onlyWorkingHours) {
    duration =
      dayjs(endDate).diff(dayjs(startDate), "days", true) *
      workDayDurationInHours *
      60 *
      60;
  } else {
    duration = dayjs(endDate).diff(dayjs(startDate), "seconds", true);
  }
  return Math.round(duration);
};

export const expectedMeasurementsCount = (args: {
  dateRange: DateRange;
  onlyWorkingHours: boolean;
}): number => durationInSecs(args) * (expectedMeasurementsPerHour / 60 / 60);

export const measurementCountToDurationInSecs = (
  count: number,
  confidence: number
): number => {
  let countCorrectedByConfidence = confidence;
  if (confidence > 0) {
    countCorrectedByConfidence = count / confidence;
  }
  return Math.round(
    (countCorrectedByConfidence / expectedMeasurementsPerHour) * 3600
  );
};

import {
  createTheme,
  Theme as MuiTheme,
  ThemeOptions,
} from "@mui/material/styles";

import { LightState } from "../../lib/devices/lightControl";

type ChartPalette = {
  colors: typeof ChartColors;
  lineColors: typeof ChartLineColors;
};

declare module "@mui/material/styles" {
  interface Palette {
    chart: ChartPalette;
  }
  interface PaletteOptions {
    chart: ChartPalette;
  }
}

declare module "@emotion/react" {
  export interface Theme extends MuiTheme {}
}

export enum Color {
  black = "#111",
  white = "#fff",
  white300 = "#E6F8FC",
  white700 = "#F7F4D6",
  grey100 = "rgb(237, 240, 241)",
  grey300 = "#c4c4c4",
  brandPrimary = "#1d444b",
  brandLight = "#32727d",
  text = "#444",
  brandSecondary = "#ed6b69",
  violetLightGrayish = "#F2E7FE",
  cyanGrayish = "#9AB8B8",
  cyanDarkGrayish = "#82A5A5",
  cyanDarkerGrayish = "#759595",
  cyanDark = "#018786",

  green300 = "#77C88D",
  green500 = "#489C69",
  yellow300 = "#ECE891",
  yellow500 = "#DFD25E",
  orange300 = "#EDB887",
  orange500 = "#E49941",
  red300 = "#E59391",
  red500 = "#DD716E",
}

export const lightStateUIColors: Record<LightState, string> = {
  OFF: Color.black,
  WHITE_300: Color.white300,
  YELLOW_500: Color.yellow500,
  GREEN_500: Color.green500,
  ORANGE_500: Color.orange500,
  RED_500: Color.red500,
  RED_500_PULSATING: Color.red500,
};

const ChartColors = {
  RED_LIGHT: "#F6B3B3",
  RED: "#F18D8D",
  RED_DARK: Color.brandSecondary,
  RED_VERY_DARK: "#a54b49",
  YELLOW: "#F2EE88",
  YELLOW_DARK: "#BEB93D",
  GREEN_DARK: "#219653",
  CYAN_GRAYISH: Color.cyanGrayish,
  CYAN_DARK_GRAYISH: Color.cyanDarkGrayish,
  CYAN_DARKER_GRAYISH: Color.cyanDarkerGrayish,
  CYAN_DARK: Color.cyanDark,
};

const ChartLineColors = [
  ChartColors.GREEN_DARK,
  ChartColors.YELLOW_DARK,
  ChartColors.RED_VERY_DARK,
  ChartColors.CYAN_DARK,
  ChartColors.RED_LIGHT,
] as const;

const themeBase = createTheme();

const themeConfig: ThemeOptions = {
  typography: {
    fontFamily: "'Work Sans', sans-serif",
    fontSize: 14,
    h1: {
      fontSize: "2.4rem",
      color: Color.text,
      fontWeight: "normal",
      [themeBase.breakpoints.down("xs")]: {
        fontSize: "1.4rem",
        fontWeight: "bold",
      },
    },
    h2: {
      fontSize: "1.6rem",
      fontWeight: "normal",
      color: Color.text,
    },
    h3: {
      fontSize: "1.1rem",
      fontWeight: "normal",
      color: Color.text,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "#__next": {
          display: "flex",
          height: "100%",
          width: "100%",
          [themeBase.breakpoints.down("sm")]: {
            flexDirection: "column",
          },
        },
        html: {
          height: "100%",
          fontSize: "16px",
        },
        body: {
          height: "100%",
          fontSize: "1rem",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: themeBase.palette.grey["700"],
          fontSize: 12,
          a: {
            color: Color.white,
            textDecoration: "underline",
          },
          hr: {
            background: Color.white,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 600,
          backgroundColor: Color.grey100,
        },
      },
    },
  },
  palette: {
    primary: {
      main: Color.brandPrimary,
      light: Color.brandLight,
    },
    secondary: {
      main: Color.brandSecondary,
    },
    grey: {
      "100": Color.grey100,
      "300": Color.grey300,
    },
    chart: {
      colors: ChartColors,
      lineColors: ChartLineColors,
    },
  },
};

const theme = createTheme(themeConfig);

export default theme;

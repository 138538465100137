// See https://colab.research.google.com/drive/1Pi5qUMttIg-lDgKuNHUQS4EKk0Ga7lWm?usp=sharing#scrollTo=sd9rFeKZ7lG8

import {
  MeasurementLevel,
  TimeSeriesDataPoint,
} from "../../../../../../graphql/modules/client/service/analytics";

export enum CovidIndicator {
  COVID_CONCENTRATION = "COVID_CONCENTRATION",
  COVID_HALF_LIFE = "COVID_HALF_LIFE",
}

export type CovidCalculatorArgs = {
  roomVolume: number;
  ventilationRateSi: number;
  co2Concentration: number;
  temperature: number;
  humidity: number;
};

type CovidCalculatorResult = {
  covidQuantaPerCubicMeter;
  covidHalfLifeInMinutes;
};

export type CovidIndicatorValue = TimeSeriesDataPoint & {
  id: CovidIndicator;
};

type CovidLevel = { threshold: number; level: MeasurementLevel };

export const covidConcentrationLevels = [
  { level: MeasurementLevel.LEVEL_0, threshold: 0.8 },
  { level: MeasurementLevel.LEVEL_1, threshold: 1 },
  { level: MeasurementLevel.LEVEL_2, threshold: Number.MAX_SAFE_INTEGER },
];

export const covidLevels: Record<
  CovidIndicator,
  { threshold: number; level: MeasurementLevel }[]
> = {
  COVID_CONCENTRATION: covidConcentrationLevels,
  COVID_HALF_LIFE: [
    { level: MeasurementLevel.LEVEL_0, threshold: 60.0 },
    { level: MeasurementLevel.LEVEL_1, threshold: Number.MAX_SAFE_INTEGER },
  ],
};

export const covidCalculator = (
  args: CovidCalculatorArgs
): CovidIndicatorValue[] => {
  const { covidQuantaPerCubicMeter, covidHalfLifeInMinutes } =
    getCovidQuantaFromCo2(args);
  const indicators: CovidIndicatorValue[] = [];
  if (isValid(covidQuantaPerCubicMeter)) {
    const level = findLevel(
      covidQuantaPerCubicMeter,
      covidLevels[CovidIndicator.COVID_CONCENTRATION]
    );
    indicators.push({
      id: CovidIndicator.COVID_CONCENTRATION,
      level: level.level,
      time: new Date(),
      value: covidQuantaPerCubicMeter,
    });
  }

  if (isValid(covidHalfLifeInMinutes)) {
    const level = findLevel(
      covidHalfLifeInMinutes,
      covidLevels[CovidIndicator.COVID_HALF_LIFE]
    );
    indicators.push({
      id: CovidIndicator.COVID_HALF_LIFE,
      level: level.level,
      time: new Date(),
      value: covidHalfLifeInMinutes,
    });
  }
  return indicators;
};

export const isValid = (v: number) => {
  return !Number.isNaN(v) && Number.isFinite(v);
};

const EXHALE_RATE_CO2_DEFAULT = 12e-6; // g/s
const CO2_BASE_DEFAULT = 415e-6; // m3/m3
// Molar volume of ideal gas at 25 deg C [m3/mol]: 24.47 l/mol (22.4 l/mol at 0 deg)
const MOLAR_VOLUME = 24.47e-3;
// CO2 molar mass [kg/mol], 44.01 g/mol (air molar mass is 28.97 g/mol)
const MOLAR_MASS = 44.01e-3;

// depositions 0.3-1.5 / h => in seconds
const DECAY_RATE_DEPOSITION_DEFAULT = 0.7 / 3600;

//how many people have COVID right now? 800K out of 11 million in Switzerland. One out of 5 is asymptomatic but
//infectious.
const PROBABILITY_INFECTED = 1 / 80;
const PROBABILITY_INFECTED_AT_WORK = (PROBABILITY_INFECTED * 1) / 5;

// what is the total emission rate?

//quanta / cm3
//hight emitter = 0.0019581, very high = 0.0795979, super = 0.6312327. Multiply by 10 if speaking! -> 1/5 are speaking
const EMISSION_RATE_PER_VOLUME = 0.015 * (4 / 5 + 10 * (1 / 5));
// cm3/second (7500 cm3/minute for standing, 15000 for walking)
const BREATHING_VOLUME_RATE = 150;

// [quanta/s]
const EMISSION_RATE_ONE_EMITTER =
  EMISSION_RATE_PER_VOLUME * BREATHING_VOLUME_RATE;

// emission_rate_highly_infected = 100/3600
const EMISSION_RATE_DEFAULT =
  PROBABILITY_INFECTED_AT_WORK * EMISSION_RATE_ONE_EMITTER;

const MAX_HALF_LIFE = 36000; // ten hours

/**
 Approximate expected COVID concentration and COVID half-life from IAQ measurements

 Args:
 volume (float): Room volume [m3]
 ventilation_rate_SI (float): Ventilation rate [m3/s]
 co2_concentration (int): CO2 concentration [ppm]
 air_temperature (float): air temperature [C]
 air_humidity (float): air relative humidity [%]

 Returns:
 float: approximate expected COVID quanta
 float: approximate COVID half-life in minutes

 */
export const getCovidQuantaFromCo2 = ({
  roomVolume,
  ventilationRateSi,
  co2Concentration,
  temperature,
  humidity,
}: CovidCalculatorArgs): CovidCalculatorResult => {
  // from ppm to ratio m3/m3
  const co2ConcentrationRatio = co2Concentration / 1e6;
  const { quantaOverCo2Ratio: covidOverCo2Multiplier, covidHalfLife } =
    roomCovidRiskMultiplier({
      roomVolume,
      ventilationRateSi,
      temperature,
      humidity,
    });

  const expectedCovidQuanta =
    covidOverCo2Multiplier *
    Math.max(0, co2ConcentrationRatio - CO2_BASE_DEFAULT);
  return {
    covidQuantaPerCubicMeter: expectedCovidQuanta,
    covidHalfLifeInMinutes: covidHalfLife / 60,
  };
};

export const roomCovidRiskMultiplier = ({
  roomVolume,
  ventilationRateSi,
  temperature,
  humidity,
  emissionRate = EMISSION_RATE_DEFAULT,
  decayRateDeposition = DECAY_RATE_DEPOSITION_DEFAULT,
  normalizationTime = 60 * 60,
}: {
  roomVolume: number;
  ventilationRateSi: number;
  temperature: number;
  humidity: number;
  emissionRate?: number;
  decayRateDeposition?: number;
  normalizationTime?: number;
}): {
  quantaOverCo2Ratio: number;
  covidHalfLife: number;
} => {
  //both models are linear to n_people, so the ratio does not depend on it. We can set it to whatever...
  const nPeople = 1;
  const { decayRate: decayRateInactivation, virusHalfLife } = getCovidDecayRate(
    {
      temperature,
      relativeHumidity: humidity,
      maxHalfLife: 10 * 3600,
    }
  );
  // from [m3/s] to [1/s]
  const decayRateVentilation = ventilationRateSi / roomVolume;

  const quantaAfter1h = simulateCovidOneStep({
    nPeople,
    emissionRate,
    decayRateVentilation,
    decayRateHalfLife: decayRateInactivation,
    decayRateDeposition,
    quantaInit: 0,
    dt: normalizationTime,
  });

  const co2After1h = simulateCo2OneStep({
    nPeople,
    Q: ventilationRateSi,
    volume: roomVolume,
    dt: normalizationTime,
    co2Init: CO2_BASE_DEFAULT,
  });

  const quantaOverCo2Ratio =
    quantaAfter1h / roomVolume / (co2After1h - CO2_BASE_DEFAULT);
  return {
    quantaOverCo2Ratio,
    covidHalfLife: virusHalfLife,
  };
};

export const getCovidDecayRate = ({
  temperature,
  relativeHumidity,
  maxHalfLife = MAX_HALF_LIFE,
}: {
  temperature: number;
  relativeHumidity: number;
  maxHalfLife?: number;
}): { decayRate: number; virusHalfLife: number } => {
  //we return decay_rate in 1/s and virus_half_life in s
  //we reverse engineered the formula from https://www.dhs.gov/science-and-technology/sars-airborne-calculator
  //see also: https://www.tandfonline.com/doi/full/10.1080/02786826.2020.1829536#F0004%20F0005%20F0006
  //see also: https://docs.google.com/spreadsheets/d/157Gp6kl8N2lPQlyoWolLkihvJRWIlA1vM52uCVfkgQE/edit#gid=0

  const temperatureClipped = clip(temperature, 10, 30);
  const relativeHumidityClipped = clip(relativeHumidity, 20, 70);

  const decayRateAt0 = -0.00073655;
  const gradTemperature = 0.0000215;
  const gradHumidity = 0.0000126;

  let decayRate =
    decayRateAt0 +
    gradTemperature * temperatureClipped +
    gradHumidity * relativeHumidityClipped;
  decayRate = clip(
    decayRate,
    Math.log(2) / maxHalfLife,
    Number.MAX_SAFE_INTEGER
  );

  const virusHalfLife = Math.log(2) / decayRate;

  return { decayRate, virusHalfLife };
};

/**
 Simulate CO2 transient mass-balance model.

 Args:
 n_people (float): time series of number of people in the room
 emission_rate (float): quanta emission rate of one person [quanta/s]
 decay_rate_ventilation (float): loss rate due to air ventilation [1/s]
 decay_rate_half_life (float): loss rate due to half-life of the virus [1/s]
 decay_rate_deposition (float): loss rate due to deposition to surfaces [1/s]
 quanta_init (float, optional): initial COVID quanta in the room [#quanta]. Defaults to 0.
 dt (float, optional): timeseries sample distance in time [s]. Defaults to 60 (1 minute).

 Returns:
 np.array: Simulated COVID quanta (timeseries) given n and Q for that period
 *
 */
export const simulateCovidOneStep = ({
  nPeople,
  emissionRate,
  decayRateVentilation,
  decayRateHalfLife,
  decayRateDeposition,
  quantaInit = 0,
  dt = 60,
}: {
  nPeople: number;
  emissionRate: number;
  decayRateVentilation: number;
  decayRateHalfLife: number;
  decayRateDeposition: number;
  quantaInit?: number;
  dt?: number;
}): number => {
  // loss rate per second
  const decayRateTotal =
    1 -
    (1 - decayRateVentilation) *
      (1 - decayRateHalfLife) *
      (1 - decayRateDeposition);

  // emission rate per second
  const emissionRateTotal = emissionRate * nPeople;

  // TODO: this is standard transient mass balance, add in Physics and unify with CO2 model??
  // correct solution is solving for 60 seconds the differential equation
  const quanta =
    emissionRateTotal / decayRateTotal +
    (quantaInit - emissionRateTotal / decayRateTotal) *
      Math.exp(-decayRateTotal * dt);
  return quanta;
};

/**
 Simulate CO2 transient mass-balance model for fixed in/out rates in a given period.

 Args:
 n_people (float): number of people in the room
 Q (float): ventilation rate [m3/s]
 volume (float): room volume [m3]
 dt (float): period of simulation [s]
 co2_init (float, optional): initial CO2 value in the room [m3/m3]. Defaults to None.

 Returns:
 np.array: Simulated co2 (timeseries) given n and Q for that period
 */
export const simulateCo2OneStep = ({
  nPeople,
  Q,
  volume,
  dt = 60,
  co2Init = CO2_BASE_DEFAULT,
}: {
  nPeople: number;
  Q: number;
  volume: number;
  dt?: number;
  co2Init?;
}): number => {
  const co2Base = CO2_BASE_DEFAULT;
  const exhaleRateCo2 = EXHALE_RATE_CO2_DEFAULT;

  const changeRate = Math.exp(Q * (-dt / volume));

  const co2InitRebased = co2Init - (co2Init ? co2Base : 0);

  // CO2 increase rate due to people (steady state)
  const C_p = (nPeople / Q) * ((exhaleRateCo2 * MOLAR_VOLUME) / MOLAR_MASS);

  const co2Change = C_p + (co2InitRebased - C_p) * changeRate;

  return CO2_BASE_DEFAULT + co2Change;
};

const clip = (value: number, intervalStart, intervalEnd) => {
  return Math.min(Math.max(value, intervalStart), intervalEnd);
};

const findLevel = (value: number, levels: CovidLevel[]): CovidLevel => {
  return levels.find((t) => t.threshold > value) || levels[levels.length - 1];
};

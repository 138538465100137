import React from "react";
import {
  ApolloClient,
  ApolloProvider,
  from,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { RetryLink } from "@apollo/client/link/retry";
import { createUploadLink } from "apollo-upload-client";
import withApollo from "next-with-apollo";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import theme from "../config/theme";
import { appWithTranslation } from "next-i18next";
import { relayStylePagination } from "@apollo/client/utilities";
import { CacheProvider } from "@emotion/react";
import createEmotionCache from "../createEmotionCache";
import {
  addEnvNameToSentryScope,
  initSentry,
  newSentryTransactionIdHeader,
  sentryErrorLink,
} from "../sentryClientIntegration";
import { useConfig } from "../config/hooks/useConfig";
import { TimeSeriesFragment_data } from "../modules/client/room/hooks/types/TimeSeriesFragment";
import { TimeSeriesId } from "../types/global-types";
import { timeSeriesConfigs } from "../modules/client/client/components/timeSeries/config";

const { ROOT_URL } = require("next/config").default().publicRuntimeConfig;

initSentry();

const withApolloClient = withApollo(({ initialState, ctx }) => {
  return new ApolloClient({
    link: from([
      new RetryLink({
        attempts: {
          retryIf: (error, op) => {
            // We don't retry invalid requests, as we can't access the status code here,
            // we have to rely on the error message.
            if (error.message?.includes("Received status code 400")) {
              return false;
            }

            return Boolean(error);
          },
        },
      }),
      sentryErrorLink,
      setContext(() => {
        const headers: { authorization?: string; "X-Transaction-Id": string } =
          newSentryTransactionIdHeader();

        // add auth header for ssr executed
        // queries to pass the cookie token
        const token = (ctx?.req as any)?.cookies?.token;
        if (!process.browser && token) {
          headers.authorization = token;
        }

        return {
          headers,
        };
      }) as any,

      createUploadLink({
        uri: `${!process.browser ? `${ROOT_URL}` : ""}/api/graphql`,
      }),
    ]),
    ssrMode: !process.browser,
    cache: new InMemoryCache({
      typePolicies: {
        Feature: {
          keyFields: ["name"],
        },
        TimeSeries: {
          keyFields: false,
          fields: {
            data: {
              read(data: TimeSeriesFragment_data[], { readField }) {
                const timeSeriesId = readField("id") as TimeSeriesId;
                if (
                  timeSeriesId &&
                  timeSeriesConfigs[timeSeriesId]?.dataMappingFn
                ) {
                  return timeSeriesConfigs[timeSeriesId]?.dataMappingFn(data);
                }
                return data;
              },
            },
          },
        },
        Histogram: {
          keyFields: false,
        },
        PerformanceIndicator: {
          keyFields: false,
        },
        HourlyAveragePerWeekday: {
          keyFields: false,
        },
        Client: {
          fields: {
            activeFeatures: {
              merge: false,
            },
            buildings: relayStylePagination(),
            deviceIssues: relayStylePagination(),
            roomIssues: relayStylePagination(),
          },
        },
        Building: {
          fields: {
            rooms: relayStylePagination(),
          },
        },
      },
    }).restore(initialState || {}),
  }) as any;
});

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

function MyApp({
  Component,
  pageProps,
  apollo,
  emotionCache = clientSideEmotionCache,
}: any) {
  return (
    <CacheProvider value={emotionCache}>
      <MuiThemeProvider theme={theme}>
        <ApolloProvider client={apollo}>
          <ApolloApp Component={Component} pageProps={pageProps} />
        </ApolloProvider>
      </MuiThemeProvider>
    </CacheProvider>
  );
}

function ApolloApp({ Component, pageProps }: any) {
  const { data } = useConfig();
  if (data) {
    addEnvNameToSentryScope(data.config.envName);
  }
  return (
    <>
      <CssBaseline />
      <Component {...pageProps} />
    </>
  );
}

export default withApolloClient(appWithTranslation(MyApp));

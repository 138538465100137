import dayjs from "dayjs";

type LastObservationCarriedForwardOptions = {
  getNow?: () => Date;
  lastMeasurementMaxDelayInSeconds?: number;
};

type DataPoint = { time: any; value: number };

type LastObservationCarriedForwardFn = <T extends DataPoint>(
  dataPoints: T[]
) => T[];

const lastObservationCarriedForwardDefaults: LastObservationCarriedForwardOptions =
  {
    getNow: () => new Date(),
    lastMeasurementMaxDelayInSeconds: 0,
  };

export const getLastObservationCarriedForwardFn = (
  options?: LastObservationCarriedForwardOptions
): LastObservationCarriedForwardFn => {
  return (dataPoints) =>
    lastObservationCarriedForward(dataPoints, {
      ...lastObservationCarriedForwardDefaults,
      ...options,
    });
};

const lastObservationCarriedForward = <T extends DataPoint>(
  dataPoints: T[],
  {
    getNow,
    lastMeasurementMaxDelayInSeconds,
  }: LastObservationCarriedForwardOptions
): T[] => {
  return dataPoints.flatMap((item, index) => {
    const next = dataPoints[index + 1];

    if (!next) {
      // Add an additional data point at the end of the time series with time
      // now if the last received data point was received not later than
      // lastMeasurementMaxDelayInSeconds in the past.
      const now = getNow();
      const lastMeasurementDate = dayjs(item.time);
      if (
        lastMeasurementDate.isBefore(now) &&
        lastMeasurementDate
          .add(lastMeasurementMaxDelayInSeconds, "seconds")
          .isAfter(now)
      ) {
        return [
          item,
          {
            ...item,
            time: now.toISOString(),
          },
        ];
      }
      return [item];
    }

    if (item.value === next.value) {
      return [item];
    }

    return [
      item,
      {
        ...item,

        // next.time is a string in ISO format
        time: new Date(new Date(next.time).getTime() - 1).toISOString(),
      },
    ];
  });
};
